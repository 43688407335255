import Repository from "../Services/Repository.js";

export default {
	state: {
		userList: {}, //Is an object because it contains pagination data as well as the list of users
		postList: {}, //Same for posts
		invoiceList: [],
		certificateList: [],
		adminError: null
	},
	getters: {
		standardPriceRate() {
			return 345.0
		}
	},
	mutations: {
		SET_USERS(state, users) {
			state.userList = users;
		},
		SET_POSTS(state, posts) {
			state.postList = posts;
		},
		SET_INVOICE_DETAIL(state, detail) {
			state.invoiceDetail = detail;
		},
		SET_INVOICE_LIST(state, list) {
			state.invoiceList = list;
		},
		SET_CERTIFICATE_LIST(state, list) {
			state.certificateList = list;
		},
		SET_ADMIN_ERROR(state, e) {
			state.adminError = e;
		}
	},
	actions: {
		retrieveUsers(context, payload) {
			const token = localStorage.getItem("access_token");

			const endpoint = 'admin/users?page=' + payload.page + '&pageSize=' + payload.pageSize
				+ '&buyersOnly=' + payload.filterBuyers + '&sellersOnly=' + payload.filterSellers;

			Repository.get(endpoint, {
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(
					response => {
						context.commit("SET_USERS", response.data);
					});
		},
		retrievePosts(context, payload) {
			const token = localStorage.getItem("access_token");

			Repository.get('admin/posts?page=' + payload.page + '&pageSize=' + payload.pageSize, {
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(
					response => {
						context.commit("SET_POSTS", response.data);
					});
		},
		retrieveInvoiceList(context, daysAfterCreation) {
			const token = localStorage.getItem("access_token");

			Repository.get('admin/invoice-list?daysAfterCreation=' + daysAfterCreation, {
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(
					response => {
						context.commit("SET_INVOICE_LIST", response.data);
					});
		},
		retrieveCertificateList(context, daysAfterCreation) {
			const token = localStorage.getItem("access_token");

			Repository.get('admin/certificate-list?daysAfterCreation=' + daysAfterCreation, {
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(
					response => {
						context.commit("SET_CERTIFICATE_LIST", response.data);
					});
		},
		approvePost: async function (context, id) {
			const token = localStorage.getItem("access_token");

			return new Promise((resolve, reject) => {
				Repository.patch('admin/approve-post?id=' + id, '', {
					headers: { Authorization: `Bearer ${token}` }
				})
					.then(
						response => {
							resolve(response.data);
						})
					.catch(e => {
						context.commit("SET_ADMIN_ERROR", e);
						reject(e.response.data.response);
					});
			})
		},
		unapprovePost: async function (context, id) {
			const token = localStorage.getItem("access_token");

			return new Promise((resolve, reject) => {
				Repository.patch('admin/unapprove-post?id=' + id, '', {
					headers: { Authorization: `Bearer ${token}` }
				})
					.then(
						response => {
							resolve(response.data);
						})
					.catch(e => {
						context.commit("SET_ADMIN_ERROR", e);
						reject(e.response.data.response);
					});
			})
		},
		disableUser: async function (context, payload) {
			const token = localStorage.getItem("access_token");

			return new Promise((resolve, reject) => {
				Repository.post('admin/disable?id=' + payload.id + "&days=" + payload.disableFor, {}, {
					headers: { Authorization: `Bearer ${token}` }
				})
					.then(
						response => {
							resolve(response.data);
						})
					.catch(e => {
						context.commit("SET_ADMIN_ERROR", e);
						reject(e.response.data.response);
					});
			})
		},
		unlockUser: async function (context, id) {
			const token = localStorage.getItem("access_token");

			return new Promise((resolve, reject) => {
				Repository.post('admin/unlock?id=' + id, {}, {
					headers: { Authorization: `Bearer ${token}` }
				})
					.then(
						response => {
							resolve(response.data);
						})
					.catch(e => {
						context.commit("SET_ADMIN_ERROR", e);
						reject(e.response.data.response);
					});
			})
		},
		async updateUserPriceRate(context, payload) {
			const token = localStorage.getItem("access_token");

			return new Promise((resolve, reject) => {
				Repository.put('admin/user-pricerate?userId=' + payload['userId'],
					{
						PriceRate: payload['newPriceRate']
					}, {
					headers: { Authorization: `Bearer ${token}` }
				})
					.then(
						response => {
							resolve(response.data);
						})
					.catch(e => {
						context.commit("SET_ADMIN_ERROR", e);
						reject(e.response.data.response);
					});
			})
		},
	}
};
