<template>
  <div class="container">
    <div>
      <p v-if="errorMessage !== null">{{ errorMessage }}</p>
    </div>
    <!-- Post Detail -->
    <div v-if="loadedPost">
      <v-row>
        <v-col class="pa-0 ma-0">
          <v-list-item two-line>
            <v-list-item-avatar tile size="60">
              <img src="@/assets/icons/widget.svg" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="headline">
                {{
                  userIsOwnerOfPost
                    ? loadedPost.reference
                    : loadedPost.activityHeading
                }}</v-list-item-title
              >
              <v-list-item-subtitle>
                {{
                  userIsOwnerOfPost || role === "Admin" ? "Created" : "Listed"
                }}
                {{
                  userIsOwnerOfPost || role === "Admin"
                    ? loadedPost.creationDate
                    : loadedPost.activeStartDate[0] | formatDate
                }}</v-list-item-subtitle
              >
            </v-list-item-content>
            <v-spacer v-if="userIsOwnerOfPost"></v-spacer>
            <sellerActions
              :siteStatus="loadedPost.siteStatus"
              v-if="userIsOwnerOfPost"
            />

            <adminPostActionNav
              v-if="role === 'Admin'"
              :isApproved="loadedPost.approved"
              :createdBy="loadedPost.createdBy"
              class="mx-6"
            />
            <v-btn
              tile
              color="secondary"
              class="ma-4"
              v-bind="size"
              v-if="
                role === 'Interested Party' &&
                  loadedPost.siteStatus === 'For Sale' &&
                  !loadedPost.hasExpressedInterest
              "
              :to="{
                name: 'ExpressInterest',
                params: { id: $route.params.id }
              }"
              >Express Interest</v-btn
            >
          </v-list-item>
        </v-col>
      </v-row>
      <v-row>
        <!-- Role Based Actions -->
        <v-list-item class="mx-4" v-if="role === 'Interested Party'"
          ><a
            @click="
              !loadedPost.hasExpressedInterest
                ? $router.push({
                    name: 'ExpressInterest',
                    params: { id: $route.params.id }
                  })
                : ''
            "
            >{{
              !loadedPost.hasExpressedInterest
                ? "You have not yet expressed interest in this post."
                : "You have expressed an interest in this post."
            }}</a
          >
        </v-list-item>
      </v-row>

      <v-row style="background: white; borderRadius: 3px" class="my-2 mx-1">
        <v-col cols="12" md="6" :order="role !== 'Interested Party' ? 1 : 6">
          <v-list v-if="userIsOwnerOfPost || role === 'Admin'">
            <v-list-item>
              <h3>Status</h3>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <strong>Business ID</strong><v-spacer></v-spacer
              >{{ loadedPost.businessId }}
            </v-list-item>
            <v-list-item>
              <strong>Posting Status</strong><v-spacer></v-spacer
              >{{ loadedPost.siteStatus }}
            </v-list-item>
            <v-list-item>
              <strong>Start Date</strong><v-spacer></v-spacer
              >{{
                loadedPost.activeStartDate.length > 0
                  ? $options.filters.formatDate(loadedPost.activeStartDate[0])
                  : "None"
              }}
            </v-list-item>
            <v-list-item>
              <strong>Expiry Date</strong>

              <v-spacer></v-spacer
              >{{
                loadedPost.activeEndDate.length > 0
                  ? $options.filters.formatDate(loadedPost.activeEndDate[0])
                  : "None"
              }}
            </v-list-item>
            <v-list-item v-if="role === 'Admin'">
              <strong>Approved by Admin?</strong><v-spacer></v-spacer
              >{{ loadedPost.approved ? "Yes" : "No" }}
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12" md="6" order="3" order-sm="2">
          <v-list>
            <v-list-item>
              <h3>Details</h3>
              <v-spacer></v-spacer>
              <v-icon
                class="mx-1"
                @click="$router.push({ name: 'AmendPostDetail' })"
                v-if="userIsOwnerOfPost"
                >mdi-pencil</v-icon
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item v-if="userIsOwnerOfPost">
              <strong>Activity Heading</strong><v-spacer></v-spacer
              >{{ loadedPost.activityHeading }}
            </v-list-item>
            <v-list-item v-if="role === 'Admin'">
              <strong>Reference</strong><v-spacer></v-spacer
              >{{ loadedPost.reference }}
            </v-list-item>
            <v-list-item v-if="role === 'Admin'">
              <strong>IP's Name</strong><v-spacer></v-spacer
              >{{ loadedPost.createdByName }}
            </v-list-item>
            <v-list-item v-if="role === 'Admin'">
              <strong>IP's Company</strong><v-spacer></v-spacer
              >{{ loadedPost.createdByCompany }}
            </v-list-item>
            <v-list-item v-if="userIsOwnerOfPost || role === 'Admin'">
              <strong>Appointment</strong><v-spacer></v-spacer
              >{{ loadedPost.businessStatus }}
            </v-list-item>
            <v-list-item>
              <strong>Trading</strong><v-spacer></v-spacer
              >{{ loadedPost.tradingStatus }}
            </v-list-item>
            <v-list-item
              v-for="(locationGroup, index) in chunkedLocations"
              :key="index"
            >
              <strong v-if="index === 0">Location(s)</strong
              ><v-spacer></v-spacer
              ><v-chip
                v-for="location in locationGroup"
                :key="location.locationId"
                class="mx-1"
                >{{ location.description }}</v-chip
              >
            </v-list-item>
            <v-list-item>
              <strong>Turnover</strong><v-spacer></v-spacer
              ><v-chip>{{ loadedPost.turnover }}</v-chip>
            </v-list-item>
            <v-list-item>
              <strong>Employees</strong><v-spacer></v-spacer
              ><v-chip>{{ loadedPost.employee }}</v-chip>
            </v-list-item>
            <v-list-item v-if="userIsOwnerOfPost || role === 'Admin'">
              <strong>Display Publicly?</strong><v-spacer></v-spacer
              >{{ loadedPost.displayHome ? "Yes" : "No" }}
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12" md="6" order="4">
          <v-list>
            <v-list-item>
              <h3>Categories</h3>
              <v-spacer></v-spacer>
              <v-menu
                open-on-hover
                v-if="userIsOwnerOfPost"
                transition="slide-y-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="mx-1"
                    v-on="on"
                    @click="
                      $router.push({
                        name: 'AmendPostCategories'
                      })
                    "
                    >mdi-pencil</v-icon
                  >
                </template>
                <v-list>
                  <v-list-item-group style="minWidth: 230px">
                    <v-list-item
                      :to="{
                        name: 'AmendPostPrimaryCategory',
                        params: { id: $route.params.id }
                      }"
                    >
                      <v-list-item-avatar>
                        <v-img
                          src="@/assets/icons/seller_amend posting category.svg"
                        ></v-img>
                      </v-list-item-avatar>
                      <v-list-item-title>Primary Category</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      :to="{
                        name: 'AmendPostCategories',
                        params: { id: $route.params.id }
                      }"
                    >
                      <v-list-item-avatar>
                        <v-img
                          src="@/assets/icons/seller_amend posting additional categories.svg"
                        ></v-img>
                      </v-list-item-avatar>
                      <v-list-item-title
                        >Additional Categories</v-list-item-title
                      >
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <strong>Primary Category</strong><v-spacer></v-spacer
              ><v-chip
                class="mx-1"
                v-if="loadedPost.primaryCategory.length > 0"
                >{{ loadedPost.primaryCategory[0].description }}</v-chip
              >
            </v-list-item>
            <v-list-item>
              <strong>Additional Categories</strong><v-spacer></v-spacer
              ><v-chip v-if="loadedPost.categories.length > 0">{{
                loadedPost.categories[0].description
              }}</v-chip>
            </v-list-item>
            <v-list-item
              v-for="(category, index) in loadedPost.categories"
              :key="category.categoryId"
              v-show="loadedPost.categories.length > 0 && index !== 0"
              ><v-spacer></v-spacer><v-chip>{{ category.description }}</v-chip>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12" md="6" order="4">
          <v-list>
            <v-list-item>
              <h3>Assets</h3>
              <v-spacer></v-spacer>
              <v-icon
                class="mx-1"
                @click="$router.push({ name: 'AmendPostAssets' })"
                v-if="userIsOwnerOfPost"
                >mdi-pencil</v-icon
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              v-for="(assetGroup, index) in chunkedAssets"
              :key="index"
            >
              <v-chip
                v-for="asset in assetGroup"
                :key="asset.assetId"
                class="mx-1"
                >{{ asset.description }}</v-chip
              >
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12" md="6" order="5">
          <v-list>
            <v-list-item>
              <h3>Description</h3>
              <v-spacer></v-spacer>
              <v-icon
                class="mx-1"
                @click="
                  $router.push({
                    name: 'AmendPostDescription'
                  })
                "
                v-if="userIsOwnerOfPost"
                >mdi-pencil</v-icon
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <div class="editr--content" v-html="loadedPost.description"></div>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col
          cols="12"
          md="6"
          order="2"
          order-sm="3"
          v-if="userIsOwnerOfPost || role === 'Admin'"
        >
          <v-list>
            <v-list-item>
              <h3>Received Interest</h3>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              v-for="interest in sortedInterest"
              :key="interest.expressionId"
              :to="{
                name: 'InterestDetail',
                params: { id: interest.expressionId }
              }"
            >
              {{ interest.createdDate | formatDate }}<v-spacer></v-spacer>
              {{ interest.viewedByInsolvency ? "Viewed" : "Not Viewed" }}
              &ensp;
              <v-icon>mdi-eye</v-icon>
            </v-list-item>
            <v-list-item v-if="sortedInterest.length === 0">
              This post is yet to receive any Interest
            </v-list-item>
          </v-list>
        </v-col>

        <v-col
          cols="12"
          md="6"
          order="2"
          order-sm="3"
          v-if="userIsOwnerOfPost || role === 'Admin'"
        >
          <v-list>
            <v-list-item>
              <h3>Invoices</h3>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              v-for="invoice in sortedInvoices"
              :key="invoice.invoiceId"
              :to="{
                name: 'InvoiceOutput',
                params: { id: invoice.invoiceId }
              }"
              >Invoice {{ invoice.invoiceId }} <v-spacer></v-spacer>Created
              {{ invoice.createdDate | formatDate }} &ensp;
              <v-icon>mdi-eye</v-icon>
            </v-list-item>
            <v-list-item v-if="loadedPost.invoice.length === 0">
              This Post has no Billable Periods
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<style>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
</style>
<script>
import { mapGetters } from "vuex";
import moment from "moment";

//Helper(s)
const chunkArray = (array, size) => {
  let result = [];
  for (let i = 0; i < array.length; i += size) {
    let chunk = array.slice(i, i + size);
    result.push(chunk);
  }
  return result;
};

export default {
  name: "PostDetail",
  metaInfo() {
    return {
      title: "IP-BID.com - View Business Posting"
    };
  },
  components: {
    //components that may not necessarily be loaded in all cases should be lazy loaded
    adminPostActionNav: () =>
      import("./Admin Components/adminPostActionNav.vue"),
    sellerActions: () => import("./Seller Components/sellerActions.vue")
  },
  data() {
    return {
      errorMessage: null
    };
  },
  created() {
    this.$store.dispatch("retrievePostByID", this.$route.params.id).catch(e => {
      this.errorMessage = e;
    });
  },
  computed: {
    ...mapGetters(["role", "userId", "loadedPost"]),
    userIsOwnerOfPost() {
      return this.loadedPost && this.userId === this.loadedPost.createdBy;
    },
    sortedInterest() {
      const expressionsOfInterest = [...this.loadedPost.expressionsOfInterest];

      return (
        (this.userIsOwnerOfPost || this.role === "Admin") &&
        expressionsOfInterest.sort((a, b) =>
          Date.parse(a.createdDate) > Date.parse(b.createdDate) ? -1 : 1
        )
      );
    },
    sortedInvoices() {
      const posts = [...this.loadedPost.invoice];
      return posts.sort((a, b) =>
        Date.parse(a.createdDate) > Date.parse(b.createdDate) ? -1 : 1
      );
    },
    chunkedAssets() {
      return chunkArray(this.loadedPost.assets, 3);
    },
    chunkedLocations() {
      return chunkArray(this.loadedPost.locations, 3);
    },
    size() {
      const size = {
        xs: "x-small",
        sm: "small"
      }[this.$vuetify.breakpoint.name];
      return size ? { [size]: true } : {};
    }
  },
  filters: {
    formatDate(date) {
      return moment(date).format("DD MMMM YYYY");
    }
  }
};
</script>

<style lang="sass" scoped>
.detail
	border: 1px solid black
	padding: 10px
	margin: 20px 15% 20px 15%
	text-align: center

.contentBlock
	background: white
</style>
