<template>
  <div class="container">
    <!-- Post Detail -->
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="headline"
          >You have {{ seller.alreadyExistingDrafts.length }} <br />
          posts in draft</v-card-title
        >

        <v-card-text>
          Would you like to see your drafts posts first?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="info" text @click="dialog = false">
            Create New Post
          </v-btn>

          <v-btn
            color="info"
            text
            @click="dialog = false"
            :to="{ name: 'DraftPosts' }"
          >
            Go to Drafts
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col class="pa-0 ma-0">
        <v-list-item three-line>
          <v-list-item-avatar tile size="60">
            <img src="@/assets/icons/widget.svg" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="headline">
              Add a new Post</v-list-item-title
            >
            <v-list-item-subtitle>
              Fill in the form below to create a new Post as either a Draft,
              Live or Scheduled Post. You are free to change any of the below
              fields any time after the Post is created.
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row style="background: white">
      <v-col cols="12" md="6">
        <v-list>
          <v-list-item>
            <h3>Details</h3>
          </v-list-item>
          <v-divider class="pb-2"></v-divider>

          <v-list-item>
            <v-col cols="3"><strong>Reference</strong></v-col>
            <v-col cols="9" class="py-0">
              <v-text-field
                hint="The Posting Reference is for your use only and is not visible to potential buyers."
                persistent-hint
                v-model="form.reference"
                :error-messages="referenceErrors"
              ></v-text-field>
            </v-col>
          </v-list-item>

          <v-list-item>
            <v-col cols="3"><strong>Activity heading</strong></v-col>
            <v-col cols="9" class="py-0">
              <v-text-field
                hint="The Activity Heading summarises the business activity of the posting and will be visible to potential buyers."
                persistent-hint
                v-model="form.activityHeading"
                :error-messages="activityHeadingErrors"
              ></v-text-field>
            </v-col>
          </v-list-item>

          <v-list-item>
            <v-col cols="3"><strong>Appointment Status</strong></v-col>
            <v-col cols="9" class="py-0">
              <v-select
                :items="seller.newPostLabels.appointmentStatusLabels"
                item-text="description"
                item-value="businessStatusId"
                v-model="form.businessStatusId"
                :error-messages="appointmentStatusErrors"
              ></v-select>
            </v-col>
          </v-list-item>

          <v-list-item>
            <v-col cols="3"><strong>Trading Status</strong></v-col>
            <v-col cols="9" class="py-0">
              <v-select
                :items="seller.newPostLabels.tradingStatusLabels"
                item-text="description"
                item-value="tradingStatusId"
                v-model="form.tradingStatusId"
                :error-messages="tradingStatusErrors"
              ></v-select>
            </v-col>
          </v-list-item>

          <v-list-item>
            <v-col cols="3"><strong>Location(s)</strong></v-col>
            <v-col cols="9" class="py-0">
              <v-select
                :items="seller.newPostLabels.locationLabels"
                hint="You can select national for all locations or select individual/multiple regions."
                persistent-hint
                item-text="description"
                item-value="locationId"
                v-model="form.locations"
                :error-messages="locationErrors"
                multiple
                chips
              >
                <template v-slot:prepend-item>
                  <v-list-item @click="anyLocation">
                    <v-list-item-action>
                      <v-icon>{{
                        allLocationsSelected
                          ? "mdi-close-box"
                          : "mdi-checkbox-blank-outline"
                      }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>National</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
            </v-col>
          </v-list-item>

          <v-list-item>
            <v-col cols="3"><strong>Turnover</strong></v-col>
            <v-col cols="9" class="py-0">
              <v-select
                :items="seller.newPostLabels.turnoverLabels"
                item-text="description"
                item-value="turnoverId"
                v-model="form.turnoverId"
                :error-messages="turnoverErrors"
                chips
              ></v-select>
            </v-col>
          </v-list-item>

          <v-list-item>
            <v-col cols="3"><strong>Employees</strong></v-col>
            <v-col cols="9" class="py-0">
              <v-select
                :items="seller.newPostLabels.employeeFields"
                item-text="description"
                item-value="employeesId"
                v-model="form.employeesId"
                :error-messages="employeeErrors"
                chips
              ></v-select>
            </v-col>
          </v-list-item>

          <v-list-item>
            <v-col cols="3"><strong>Display Publicly?</strong></v-col>
            <v-col cols="9" class="py-0">
              <span style="color: grey; fontSize: 0.8em"
                >Selecting yes will allow your post to be shown to
                unauthenticated users via the home page.</span
              >
              <v-btn-toggle
                v-model="form.displayHome"
                mandatory
                :color="form.displayHome ? 'info' : 'var(--v-error-base)'"
              >
                <v-btn small :value="false">
                  No
                </v-btn>
                <v-btn small :value="true">
                  Yes
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-list-item>
        </v-list>
      </v-col>

      <v-col cols="12" md="6" order="4">
        <v-list-item>
          <h3>Categories</h3>
          <v-spacer></v-spacer>
          <span
            style="color: var(--v-error-base) !important "
            v-if="primaryCategoryErrors.length > 0"
          >
            Primary Category is required</span
          >
        </v-list-item>
        <v-divider class="pb-2"></v-divider>

        <v-expansion-panels
          flat
          style="border: 1px solid lightgrey"
          focusable
          accordion
        >
          <v-expansion-panel>
            <v-expansion-panel-header
              ><strong>Primary Category</strong><v-spacer></v-spacer
              >{{
                form.primaryCategory > 0 ? "Selected" : ""
              }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <businessCategoryPicker
                :radio="true"
                v-model="form.primaryCategory"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel :disabled="!(form.primaryCategory > 0)">
            <v-expansion-panel-header
              ><strong>Additional Categories</strong><v-spacer></v-spacer
              >{{
                form.primaryCategory > 0
                  ? form.additionalCategories.length + " Selected"
                  : "Please select a Primary Category before selecting Additional Categories"
              }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <businessCategoryPicker
                :showSelected="true"
                :showSearch="true"
                :existing="[]"
                :exclude="form.primaryCategory"
                v-model="form.additionalCategories"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" md="6" order="4">
        <v-list-item>
          <h3>Assets</h3>
          <v-spacer></v-spacer>
        </v-list-item>
        <v-divider class="pb-2"></v-divider>
        <v-list-item>
          <v-select
            :items="seller.newPostLabels.assetLabels"
            item-text="description"
            item-value="assetId"
            v-model="form.assets"
            multiple
            chips
          ></v-select>
        </v-list-item>
      </v-col>
      <v-col cols="12" md="6" order="5">
        <v-list-item>
          <h3>Description</h3>

          <v-spacer></v-spacer>
        </v-list-item>
        <v-divider class="pb-2"></v-divider>
        <div class="v-text-field__details" v-if="descriptionErrors.length != 0">
          <div class="v-messages theme--light error--text" role="alert">
            <div class="v-messages__wrapper">
              <div class="v-messages__message">
                {{ descriptionErrors[0] }}
              </div>
            </div>
          </div>
        </div>
        <wysiwyg
          v-model="form.description"
          :error-messages="descriptionErrors"
        />
      </v-col>
    </v-row>
    <v-btn
      tile
      color="secondary"
      @click="saveDraft"
      :loading="loading"
      class="ma-4"
      >Save as Draft</v-btn
    >
    <v-btn tile color="secondary" @click="postNow">Continue to post now</v-btn>
    <p v-if="errorMessage !== null">{{ errorMessage }}</p>
  </div>
</template>
<style>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
</style>
<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import businessCategoryPicker from "@/components/businessCategoryPicker.vue";

const createRequiredErrors = (field, fieldString) => {
  const errors = [];
  if (!field.$dirty) return errors;
  !field.required && errors.push(fieldString + " is required");
  return errors;
};

export default {
  name: "AddPost",
  metaInfo() {
    return {
      title: "IP-BID.com - Add Post"
    };
  },
  mixins: [validationMixin],
  components: { businessCategoryPicker },
  data() {
    return {
      dialog: false,
      errorMessage: null,
      loading: false,
      form: {
        reference: null,
        activityHeading: null,
        locations: [],
        businessStatusId: null,
        tradingStatusId: null,
        turnoverId: null,
        employeesId: null,
        assets: [],
        description: null,
        displayHome: true,

        primaryCategory: null,

        additionalCategories: []
      }
    };
  },
  validations: {
    form: {
      reference: { required },
      activityHeading: { required },
      locations: { required },
      businessStatusId: { required },
      tradingStatusId: { required },
      turnoverId: { required },
      employeesId: { required },
      description: { required },
      displayHome: { required },
      primaryCategory: { required }
    }
  },
  async created() {
    await this.$store.dispatch("checkIfDraftsExist");
    if (this.seller.alreadyExistingDrafts.length) this.dialog = true;
    this.$store.dispatch("retrieveNewPostLabels");
  },
  methods: {
    async saveDraft() {
      this.loading = true;
      if (!this.$v.$invalid) {
        await this.$store
          .dispatch("createDraft", this.form)
          .then(response => {
            this.$router.push({
              name: "PostDetail",
              params: { id: response.businessId }
            });
          })
          .catch(e => {
            this.errorMessage = e;
          });
      }
      this.$v.form.$touch();
      this.loading = false;
    },
    async postNow() {
      if (!this.$v.$invalid) {
        await this.$store.dispatch("continueToPostNow", this.form);
        this.$router.push({ name: "PostWithoutDraft" });
      }
      this.$v.form.$touch();
    },
    anyLocation() {
      if (!this.allLocationsSelected) {
        this.form.locations = this.seller.newPostLabels.locationLabels.map(
          x => x.locationId
        );
      } else {
        this.form.locations = [];
      }
    }
  },
  computed: {
    ...mapState(["seller", "labels"]),

    allLocationsSelected() {
      return (
        this.form.locations.length ===
        this.seller.newPostLabels.locationLabels.length
      );
    },

    referenceErrors() {
      return createRequiredErrors(this.$v.form.reference, "Reference");
    },
    activityHeadingErrors() {
      return createRequiredErrors(
        this.$v.form.activityHeading,
        "Activity Heading"
      );
    },
    locationErrors() {
      return createRequiredErrors(this.$v.form.locations, "Location");
    },
    appointmentStatusErrors() {
      return createRequiredErrors(
        this.$v.form.businessStatusId,
        "Appointment Status"
      );
    },
    tradingStatusErrors() {
      return createRequiredErrors(
        this.$v.form.tradingStatusId,
        "Trading Status"
      );
    },
    turnoverErrors() {
      return createRequiredErrors(this.$v.form.turnoverId, "Turnover");
    },
    employeeErrors() {
      return createRequiredErrors(this.$v.form.employeesId, "Employees");
    },
    primaryCategoryErrors() {
      return createRequiredErrors(
        this.$v.form.primaryCategory,
        "Primary Category"
      );
    },
    descriptionErrors() {
      return createRequiredErrors(this.$v.form.description, "Description");
    }
  }
};
</script>

<style lang="sass" scoped>
.detail
	border: 1px solid black
	padding: 10px
	margin: 20px 15% 20px 15%
	text-align: center

.container
	@media screen and (max-width: 500px)
		font-size: 0.85em
</style>
